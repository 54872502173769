import { render, staticRenderFns } from "./BuildingBannerUpload.vue?vue&type=template&id=836dbb1a&scoped=true"
import script from "./BuildingBannerUpload.vue?vue&type=script&lang=js"
export * from "./BuildingBannerUpload.vue?vue&type=script&lang=js"
import style0 from "./BuildingBannerUpload.vue?vue&type=style&index=0&id=836dbb1a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "836dbb1a",
  null
  
)

export default component.exports